<template>
    <div class="widget__item widget__steps" v-if="false">
        <template v-for="(item, index) in currentSteps">
<!--            <span v-if="item.id === step && !item.hide" @next="onNextStep"></span>-->
        </template>
    </div>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        name: 'setSteps',
        components: {

        },
        data() {
            return {
                step: 1,
                user_steps: [

                ],
                admin_steps: [
                    {
                        id: 1,
                        hash: '13fd0b93d3aea9c50f870d99241bbd21',  // Пожалуйста, подтвердите свой e-mail
                        hide: false
                    }
                ],
            }
        },
        created() {
            // session
            //     .get('/api/v1/dashboard-widget/steps/')
            //     .then(response => {
            //         response.data.forEach(item => {
            //             this.user_steps.forEach(step => {
            //                 if (item.hash === step.hash) {
            //                     step.hide = true;
            //                 }
            //             });
            //             this.admin_steps.forEach(step => {
            //                 if (item.hash === step.hash) {
            //                     step.hide = true;
            //                 }
            //             })
            //         })
            //     })
            //     .catch((errors) => {
            //         console.log('errors', errors)
            //     });
        },
        computed: {
            ...mapState('default_data', [
              'current_user'
            ]),
            currentSteps: function () {
                return this.current_user.is_admin ? this.admin_steps: this.user_steps
            },
        },
        methods: {
            onNextStep() {
                this.step++;
            },
            onPrevStep() {
                this.step--;
            }
        }
    }
</script>

<style lang="sass">
    .widget__steps
        padding: 30px
        background: #FBC04F

        .steps
            &__header,
            &__body,
            &__footer
                display: flex
                width: 100%
                justify-content: space-between
                flex-wrap: wrap

            &__body
                margin-top: 15px

            &__footer
                margin-top: 50px

            &__title
                max-width: 300px

            &__count
                font-size: 1rem
                opacity: 0.4

            &__text
                text-align: left
                font-size: 1rem
                opacity: 0.7
                max-width: 400px
                width: 75%

            &__img
                height: 68px
                width: 68px
                opacity: 0.2

            &__btn
                font-size: 1rem
                margin-bottom: 10px
                margin-right: 10px
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

                &-icon
                    margin-left: 10px
        @media screen and (max-width: 1366px)
            .steps
                &__img
                    display: none

        @media screen and (max-width: 768px)
            .widget__steps
                padding: 20px
            .steps
                &__text
                    width: auto

                &__title
                    font-size: 1.125rem
                    margin-bottom: 20px !important
        @media (max-width: 500px)
            .steps
                &__wrap
                    width: 100%
                &__btn
                    font-size: 0.75rem
                    width: 100%
                    margin-right: 0
                &__count
                    font-size: 0.875rem
</style>
